import React from "react"

import * as Styled from "./styles"

import Title from "components/ui/Title"

import CheckedIcon from "assets/svgs/icons/list-element-icon-1.svg"

interface BenefitsItem {
  benefitColumn: React.ReactNode[]
}

const BenefitsData: BenefitsItem[] = [
  {
    benefitColumn: [
      "",
      <Styled.InformationText>
        {"Certificates of insurance digital turnover"}
      </Styled.InformationText>,
      <Styled.InformationText>
        {
          "Consolidated, grouped and readily available data and files in one place"
        }
      </Styled.InformationText>,
      <Styled.InformationText>
        {"Live updates regarding COI requests to all involved parties"}
      </Styled.InformationText>,
      <Styled.InformationText>
        {"Lower costs and time spent supported by clients satisfaction"}
      </Styled.InformationText>,
    ],
  },
  {
    benefitColumn: [
      <Styled.CompanyText>{"With Movezee™"}</Styled.CompanyText>,
      <Styled.ImageContainer>
        <CheckedIcon />
      </Styled.ImageContainer>,
      <Styled.ImageContainer>
        <CheckedIcon />
      </Styled.ImageContainer>,
      <Styled.ImageContainer>
        <CheckedIcon />
      </Styled.ImageContainer>,
      <Styled.ImageContainer>
        <CheckedIcon />
      </Styled.ImageContainer>,
    ],
  },
  {
    benefitColumn: [
      <Styled.CompanyText>{"Without Movezee™"}</Styled.CompanyText>,
      <Styled.ImageContainer>
        <CheckedIcon />
      </Styled.ImageContainer>,
      <Styled.EmptyCircle />,
      <Styled.EmptyCircle />,
      <Styled.EmptyCircle />,
    ],
  },
]

const BenefitsBanner: React.FC = () => {
  const benefitsItems = BenefitsData.map((benefitRow, index) => (
    <Styled.RowsGridWrapper rowIndex={index} key={index}>
      {benefitRow.benefitColumn.map((benefit, index) => (
        <Styled.BenefitColumnElement columnIndex={index} key={index}>
          {benefit}
        </Styled.BenefitColumnElement>
      ))}
    </Styled.RowsGridWrapper>
  ))

  return (
    <Styled.Wrapper>
      <Styled.HeadWrapper>
        <Title main white largeScreenFontSize="60px">
          {"Why management companies select Movezee?"}
        </Title>
      </Styled.HeadWrapper>
      <Styled.MainGridWrapper>{benefitsItems}</Styled.MainGridWrapper>
    </Styled.Wrapper>
  )
}

export default BenefitsBanner
