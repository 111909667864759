import React from "react"

import * as Styled from "./styles"

interface Props {
  text: string
  title: string
  information: string
}

const StepElement: React.FC<Props> = ({ text, title, information }) => {
  return (
    <Styled.StepElementWrapper>
      <Styled.StepText>{text.toUpperCase()}</Styled.StepText>
      <Styled.StepTitle>{title}</Styled.StepTitle>
      <Styled.StepInformation>{information}</Styled.StepInformation>
    </Styled.StepElementWrapper>
  )
}

export default StepElement
