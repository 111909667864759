import styled from "styled-components"

export const StepElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.basic};
`

export const StepText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 28px;
  }
`

export const StepTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.main2};
  line-height: 32px;

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    padding: 0.5rem 0;
  }
`

export const StepInformation = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
`
