import React from "react"

import ClientBanner from "components/ClientBanner"
import Section from "components/ui/Section"

import ClientBannerIllustration1 from "assets/svgs/client-banner-image-1.svg"
import ClientBannerIllustration2 from "assets/svgs/client-banner-image-2.svg"
import ClientBannerIllustration3 from "assets/svgs/client-banner-image-3.svg"

import { LandingPageType } from "types/enums"

interface ClientBannerObject {
  landingType: LandingPageType | undefined
  title: string
  listElements: string[]
  image: React.ReactNode
  moreDetailsRoute?: string
  greenButton?: boolean
}

const clientBannerObjects: ClientBannerObject[] = [
  {
    landingType: undefined,
    title: "For tenants",
    listElements: [
      "Schedule your move or delivery seamlessly",
      "Receive status notifications for your move",
      "Always up to date",
    ],
    image: <ClientBannerIllustration1 />,
    greenButton: true,
  },
  {
    landingType: LandingPageType.MANAGEMENT_COMPANIES,
    title: "For Management Companies",
    listElements: [
      "Efficiently track and approve COI requests for moves, deliveries and vendors",
      "Reduce the time spent to approve COIs dramatically while improving compliance times",
      "Seamless communication between all parties involved",
    ],
    image: <ClientBannerIllustration2 />,
    moreDetailsRoute: "/for-management-companies/",
  },
  {
    landingType: LandingPageType.VENDORS,
    title: "For Vendors",
    listElements: [
      "Upload COIs directly to management companies to eliminate archaic emailing back and forth",
      "Full alignment with management companies, tenants and even front desks",
      "Always up to date regarding your COI  or other certificates status ",
    ],
    image: <ClientBannerIllustration3 />,
    moreDetailsRoute: "/for-vendors",
  },
]

const ClientBannersSection: React.FC = () => {
  const ClientBanners = clientBannerObjects.map((banner, index) => (
    <ClientBanner
      key={index}
      title={banner.title}
      listElements={banner.listElements}
      image={banner.image}
      landingType={banner.landingType}
      moreDetailsRoute={banner.moreDetailsRoute}
      greenButton={banner.greenButton}
    />
  ))

  return <Section>{ClientBanners}</Section>
}

export default ClientBannersSection
