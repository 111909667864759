import styled from "styled-components"
import { LandingPageType } from "types/enums"

export interface StyledProps {
  color?: string
  weight?: string
  landingType?: LandingPageType
}

export const SpanWrapper = styled.span`
  display: flex;
  align-items: center;
  margin: 1rem 0 1rem 0.25rem;
`

export const TextContent = styled.span`
  margin: 0 0 0 1rem;
`

export const ImageWrapper = styled.figure`
  svg {
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`

export const ListElement = styled.li<StyledProps>`
  color: ${({ theme, color }) => (color ? color : theme.colors.text1)};
  text-align: left;
  font-size: ${({ theme }) => theme.mobile.text.size};
  font-weight: ${({ theme, weight }) =>
    weight ? weight : theme.mobile.text.weight};

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: ${({ theme }) => theme.desktop.text.size};
  }
`
