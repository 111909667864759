import styled from "styled-components"

export const FaqContainer = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(17, 123, 175, 0.1);
  margin-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    padding-bottom: 1.5rem;
  }
`

export const FaqElementHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const FaqElementTitle = styled.p`
  font-size: ${({ theme }) => theme.desktop.text.size};
  font-weight: ${({ theme }) => theme.mobile.title.weight};
  font-family: ${({ theme }) => theme.fonts.main2};
  color: ${({ theme }) => theme.colors.basic};
  padding-bottom: 0.5rem;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    font-size: ${({ theme }) => theme.mobile.subTitle.size};
    padding-bottom: 1.5rem;
  }
`

export const ExpandIconWrapper = styled.figure`
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    svg {
      width: 48px;
      height: 48px;
    }
  }
`

export const FaqInformationSpan = styled.p`
  font-size: ${({ theme }) => theme.mobile.text.size};
  font-weight: ${({ theme }) => theme.mobile.text.weight};
  color: ${({ theme }) => theme.colors.basic};
  line-height: 20px;
  width: 95%;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    font-size: ${({ theme }) => theme.desktop.text.size};
    line-height: 28px;
    width: 75%;
  }
`
