import React from "react"

import MainIllustration from "assets/svgs/main-5.svg"
import MainInfoBlock from "components/MainInfoBlock"
import Section from "components/ui/Section"

import { LandingPagesProps } from "types/interfaces"

interface MainInfoBlockObject {
  subtitle: string
  reverse: boolean
  textParagraphs: string[]
}

const texts: MainInfoBlockObject = {
  subtitle: "About the problems with comunication",
  reverse: false,
  textParagraphs: [
    "A Certificate of Insurance (COI) shows proof of insurance coverage for a particular entity. It ensures that the vendor has adequate insurance coverage in the event of a claim.",
    "Requesting COI is essential for every party involved in the process to get appropriate coverage protection and minimize risk exposure. Movezee provides you with a fast and effortless COI process to make you feel safe and ready for your move. All simple and automatic.",
    "Thanks to this document, you, the vendor, and the management company can easily complete all the formalities and get your move going.",
  ],
}

const HomeMainInfo: React.FC<LandingPagesProps> = ({ landingType }) => {
  const title = (
    <p>
      Why is COI <span className="lighter">important?</span>
    </p>
  )

  return (
    <Section>
      <MainInfoBlock
        subtitle={{ text: texts.subtitle }}
        reverse={texts.reverse}
        textParagraphs={texts.textParagraphs}
        image={<MainIllustration />}
        title={title}
        landingType={landingType}
        expandButtonText="More details"
      />
    </Section>
  )
}

export default HomeMainInfo
