import React from "react"

import * as Styled from "./styles"

import ListElementIcon1 from "assets/svgs/icons/list-element-icon-1.svg"
import ListElementIcon2 from "assets/svgs/icons/list-element-icon-2.svg"
import ListElementIcon3 from "assets/svgs/icons/list-element-icon-3.svg"
import { LandingPageType } from "types/enums"

interface Props extends Styled.StyledProps {
  text: string
  landingType?: LandingPageType
}

const ListElement: React.FC<Props> = ({ text, landingType, color, weight }) => {
  const getListElementIcon = (
    landingType?: LandingPageType
  ): React.ReactNode => {
    switch (landingType) {
      case LandingPageType.MANAGEMENT_COMPANIES:
        return <ListElementIcon1 />
      case LandingPageType.VENDORS:
        return <ListElementIcon3 />
      default:
        return <ListElementIcon2 />
    }
  }

  return (
    <Styled.ListElement color={color} weight={weight}>
      <Styled.SpanWrapper>
        <Styled.ImageWrapper>
          {getListElementIcon(landingType)}
        </Styled.ImageWrapper>
        <Styled.TextContent>{text}</Styled.TextContent>
      </Styled.SpanWrapper>
    </Styled.ListElement>
  )
}

export default ListElement
