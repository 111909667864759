import styled from "styled-components"
import { FlexWrapper } from "components/ui/FlexWrapper/styles"
import { LandingPageType } from "types/enums"

export interface StyledProps {
  landingType?: LandingPageType
}

export const Wrapper = styled.div`
  margin: 0 0 4rem 0;
  width: 100%;
  max-width: 100vw;

  ${({ theme }) => theme.breakpoints.tablet} {
    border-radius: 24px;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    background: ${({ theme }) => theme.gradient.gradientLightBlue};
    max-width: 1440px;
  }
`
export const ContentWrapper = styled(FlexWrapper)`
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
`

export const Box = styled.div`
  &:first-child {
    width: 100%;

    ${({ theme }) => theme.breakpoints.smallDesktop} {
      margin: 4rem 0 4rem 5%;
      flex-basis: 50%;
    }

    ${({ theme }) => theme.breakpoints.largeDesktop} {
      flex-basis: 50%;
    }
  }

  &:nth-child(2) {
    width: 100%;

    ${({ theme }) => theme.breakpoints.smallDesktop} {
      align-self: center;
      margin: 0 2% 0 3%;
      width: 50%;
    }

    ${({ theme }) => theme.breakpoints.desktop} {
      margin: 0 2% 0 2%;
      width: 45%;
    }
  }
`

export const ListWrapper = styled.ul<StyledProps>`
  list-style: none;

  ${({ theme }) => theme.breakpoints.tablet} {
    border-radius: 24px;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    max-width: 1440px;
  }
`
