import styled from "styled-components"
import { FlexWrapper } from "components/ui/FlexWrapper/styles"

export interface RowsGridProps {
  rowIndex: number
}

export interface ItemProps {
  columnIndex: number
}

export const Wrapper = styled.div`
  ${({ theme }) =>
    `background: linear-gradient(0deg, ${theme.colors.basic} 0%, ${theme.colors.basic2} 100%);`};
  width: 100%;
  max-width: 100vw;
  margin-bottom: 4rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    border-radius: 24px;
    margin-bottom: 4rem;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    max-width: 1440px;
    margin-bottom: 8rem;
  }
`
export const HeadWrapper = styled(FlexWrapper)`
  width: 100%;
  padding: 4rem 0 2rem;
  text-align: center;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    padding: 4rem 0 1rem;
  }
`

export const MainGridWrapper = styled.div`
  display: grid;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 5rem;
  grid-template-columns: 40% 30% 30%;
  grid-template-rows: 1fr;
  grid-template-areas: "benefits-row-0 benefits-row-1 benefits-row-2";

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    width: 100%;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "benefits-row-0"
      "benefits-row-1"
      "benefits-row-2";
  }
`

export const RowsGridWrapper = styled.div<RowsGridProps>`
  grid-area: ${({ rowIndex }) => `benefits-row-${rowIndex}`};
  ${({ rowIndex }) =>
    rowIndex === 1 &&
    "background: linear-gradient(180deg, rgba(255, 255, 255, 0) 2.25%, rgba(255, 255, 255, 0.1) 41.5%, rgba(255, 255, 255, 0) 100%);"};
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 2fr 2fr 2fr 2fr;
  grid-template-areas:
    "row-column-0"
    "row-column-1"
    "row-column-2"
    "row-column-3"
    "row-column-4";

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    ${({ rowIndex }) =>
      rowIndex === 1 &&
      "background: ''; background-color: rgba(255, 255, 255, 0.1);"}
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 100%;
    grid-template-areas: "row-column-0 row-column-1 row-column-2 row-column-3 row-column-4";
  }
`

export const BenefitColumnElement = styled.div<ItemProps>`
  grid-area: ${({ columnIndex }) => `row-column-${columnIndex}`};
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid rgba(36, 195, 255, 0.5);

  &:nth-of-type(1) {
    border-bottom: none;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    flex-direction: row;
    border-bottom: none;
    align-items: center;
    justify-content: flex-start;
  }
`

export const InformationText = styled.span`
  padding: 1rem 0;
  width: 90%;
  align-self: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: white;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    padding: 1rem;
    width: 100%;
    align-self: flex-start;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    padding-bottom: 2rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: white;
  }
`

export const CompanyText = styled.span`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-family: ${({ theme }) => theme.fonts.main2};
  color: white;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    padding-left: 2rem;
    text-align: left;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
`

export const ImageContainer = styled.figure`
  margin: 0 auto;

  svg {
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`

export const EmptyCircle = styled.div`
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
`
