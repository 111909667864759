import React from "react"

import * as Styled from "./styles"

import Section from "components/ui/Section"
import StepElement from "components/ui/StepElement"
import Title from "components/ui/Title"

import StepsItemsIcon from "assets/svgs/icons/steps-elements-icon.svg"
import SmallStepsItemsIcon from "assets/svgs/icons/small-steps-elements-icon.svg"

interface StepItem {
  text: string
  title: string
  information: string
}

const StepsData: StepItem[] = [
  {
    text: "step 1",
    title: "Scan QR Code",
    information:
      "Scan the QR code or log in to the site to get started on your move or delivery.",
  },
  {
    text: "step 2",
    title: "Fill in a Request",
    information:
      "Enter your address, insert date of your move and vendor and that’s it!",
  },
  {
    text: "step 3",
    title: "Get your COI approved by the management company",
    information:
      "Once documents are in the system, the property manager will approve them.",
  },
  {
    text: "last step",
    title: "Ready to move!",
    information:
      "That’s it! You are ready to move once as your request is approved. The vendor and the front desk will be informed about the date of your move and will have all the documents handy.",
  },
]

const StepsSection: React.FC = () => {
  const StepItems = (
    <>
      <Styled.MobileStepImageWrapper>
        <SmallStepsItemsIcon />
      </Styled.MobileStepImageWrapper>
      <Styled.DesktopStepImageWrapper>
        <StepsItemsIcon />
      </Styled.DesktopStepImageWrapper>
      {StepsData.map((step, index) => (
        <Styled.StepWrapper stepIndex={index} key={index}>
          <StepElement
            text={step.text}
            title={step.title}
            information={step.information}
          />
        </Styled.StepWrapper>
      ))}
    </>
  )

  return (
    <Section fullWidth>
      <Styled.Wrapper alignCenter>
        <Title>{"How does it work?"}</Title>
        <Styled.StepElementsGridWrapper>
          {StepItems}
        </Styled.StepElementsGridWrapper>
      </Styled.Wrapper>
    </Section>
  )
}

export default StepsSection
