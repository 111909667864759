import styled from "styled-components"
import { FlexWrapper } from "components/ui/FlexWrapper/styles"

export interface StyledProps {
  stepIndex: number
}

export const Wrapper = styled(FlexWrapper)`
  width: 100%;
  max-width: 100vw;
  margin: 3rem 0 12rem;

  h1 {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: 5rem 0 10rem;
  }
`

export const StepElementsGridWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 2fr 9fr;
  grid-template-rows: 31% 24% 39% 6%;
  grid-template-areas:
    "stepImage step-0"
    "stepImage step-1"
    "stepImage step-2"
    "stepImage step-3";

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    width: 80%;
    grid-template-columns: 1fr 9fr;
    margin-top: 2rem;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 4rem;
    grid-template-columns: 3fr 9fr;
    grid-template-rows: 28% 28% 28% 16%;
  }
`

export const StepWrapper = styled.div<StyledProps>`
  grid-area: ${({ stepIndex }) => `step-${stepIndex}`};
  ${({ stepIndex }) => (stepIndex === 0 ? "padding-top: 0.5rem" : "")};
`

const StepImageWrapper = styled.div`
  grid-area: stepImage;
  justify-self: start;
`

export const MobileStepImageWrapper = styled(StepImageWrapper)`
  @media (min-width: 1279px) {
    display: none;
  }
`

export const DesktopStepImageWrapper = styled(StepImageWrapper)`
  @media (max-width: 1279px) {
    display: none;
  }
`
