import React from "react"

import Section from "components/ui/Section"
import Title from "components/ui/Title"
import FAQElement from "components/ui/FAQElement"
import { FAQItemsWrapper } from "./styles"

interface FAQItems {
  faqElementTitle: string
  faqInformation: string
}

const FaqItemsObject: FAQItems[] = [
  //TODO: Add link to download
  {
    faqElementTitle: "How can I get a Movezee?",
    faqInformation:
      "If you're a tenant or vendor simply register and start using the application. If you're a property manager, request a demo.",
  },
  // TODO: show when ready
  //   {
  //     faqElementTitle: "How long does it take to get COI with Movezee?",
  //     faqInformation: "As soon as you create a request, it is sent to the Vendor selected by you and then right into the hands of the respective Property manager for approval.",
  //   },
  //   {
  //     faqElementTitle: "How does a Vendor get the request?",
  //     faqInformation:
  //       "Standard process need up to 24h/48h to make COI agreement and few hours of interactions with participants. Our solution is able to change those hours in minutes.",
  //   },
  {
    faqElementTitle: "How will I know that request is approved?",
    faqInformation:
      "You will get notification as soon as COI is approved. The Vendor and Front Desk of your building will be informed as well.",
  },
]

const FAQSection: React.FC = () => {
  const FaqItems = FaqItemsObject.map((faqItem, index) => (
    <FAQElement
      key={index}
      faqElementTitle={faqItem.faqElementTitle}
      faqInformation={faqItem.faqInformation}
    />
  ))

  return (
    <Section fullWidth={true}>
      <Title>FAQ</Title>
      <FAQItemsWrapper id="FAQ">{FaqItems}</FAQItemsWrapper>
    </Section>
  )
}

export default FAQSection
