import * as React from "react"

import Layout from "components/Layout"
import Seo from "components/SEO"
import BenefitsBanner from "components/BenefitsBanner"
import ContactBanner from "components/ContactBanner"
import ClientBannersSection from "components/ClientBannersSection"
import FAQSection from "components/FAQSection"
import HeroBanner from "components/HeroBanner"
// import HomeAboutUs from "components/MainInfoSection/HomeAboutUs"
import HomeMainInfo from "components/MainInfoSection/HomeMainInfo"
// import TestimonialsSection from "components/TestimonialsSection"
import StepsSection from "components/StepsSection"

import { LandingPageType } from "types/enums"

const HomePage = () => (
  <Layout landingType={LandingPageType.HOME}>
    <Seo title="Home" />
    <HeroBanner landingType={LandingPageType.HOME} />
    <ClientBannersSection />
    <HomeMainInfo landingType={LandingPageType.HOME} />
    <StepsSection />
    <BenefitsBanner />
    <FAQSection />
    {/* <HomeAboutUs /> */}
    {/* <TestimonialsSection /> */}
    <ContactBanner landingType={LandingPageType.HOME} />
  </Layout>
)

export default HomePage
