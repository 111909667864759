import React from "react"
import { useMediaQuery } from "react-responsive"
import { navigate } from "gatsby"

import ImageWrapper from "components/ui/ImageWrapper"
import Button from "components/ui/Button"
import Title from "components/ui/Title"
import ListElement from "components/ui/ListElement"

import * as Styled from "./styles"

import { LandingPagesProps } from "types/interfaces"
import { LandingPageType } from "types/enums"
import { useCalendly } from "hooks/useCalendly"
import { CalendlyPopup } from "components/CalendlyPopup"

interface Props extends LandingPagesProps {
  title: string
  listElements: string[]
  image: React.ReactNode
  moreDetailsRoute?: string
  greenButton?: boolean
}

const ClientBanner: React.FC<Props> = ({
  landingType,
  title,
  listElements,
  image,
  moreDetailsRoute = "/",
  greenButton = false,
}) => {
  const { isCalendlyPopupOpen, setCalendlyPopupOpen } = useCalendly()
  const isTabletWidth = useMediaQuery({
    query: "(max-width: 1023px)",
  })

  const getStartedButtonLabel =
    landingType === LandingPageType.MANAGEMENT_COMPANIES
      ? "Request a demo"
      : "Get started"

  const onGetStartedButtonClick = () => {
    console.log("click")
    if (landingType === LandingPageType.MANAGEMENT_COMPANIES) {
      return setCalendlyPopupOpen(true)
    }

    navigate(`${process.env.GATSBY_APP_BASE_URL}/auth/signup`)
  }

  return (
    <Styled.Wrapper>
      <CalendlyPopup
        isOpen={isCalendlyPopupOpen}
        onClose={() => setCalendlyPopupOpen(false)}
      />
      <Styled.ContentWrapper>
        <Styled.Box>
          <Title largeScreenFontSize="48px">{title}</Title>
          <Styled.ListWrapper>
            {listElements.map((text, index) => (
              <ListElement key={index} landingType={landingType} text={text} />
            ))}
          </Styled.ListWrapper>
          <Button
            primary
            shadow
            landingType={landingType}
            greenButton={greenButton}
            desktopWidth={{ smallDesktop: "212px", desktop: "229px" }}
            onClick={onGetStartedButtonClick}
          >
            {getStartedButtonLabel}
          </Button>
          {landingType && !isTabletWidth && (
            <Button
              secondary
              shadow
              landingType={landingType}
              onClick={() => navigate(moreDetailsRoute)}
            >
              {"More details"}
            </Button>
          )}
        </Styled.Box>
        <Styled.Box>
          <ImageWrapper>{image}</ImageWrapper>
        </Styled.Box>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  )
}

export default ClientBanner
