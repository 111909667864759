import React, { useState } from "react"

import * as Styled from "./styles"

import HideIcon from "assets/svgs/icons/faq-hide-icon.svg"
import ShowIcon from "assets/svgs/icons/faq-show-icon.svg"

interface Props {
  faqElementTitle: string
  faqInformation: string
}

const FAQElement: React.FC<Props> = ({ faqElementTitle, faqInformation }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <Styled.FaqContainer>
      <Styled.FaqElementHeadWrapper>
        <Styled.FaqElementTitle>{faqElementTitle}</Styled.FaqElementTitle>
        <Styled.ExpandIconWrapper onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <HideIcon /> : <ShowIcon />}
        </Styled.ExpandIconWrapper>
      </Styled.FaqElementHeadWrapper>
      {isExpanded && (
        <Styled.FaqInformationSpan>{faqInformation}</Styled.FaqInformationSpan>
      )}
    </Styled.FaqContainer>
  )
}

export default FAQElement
